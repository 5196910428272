<template>
  <div class="pt-3">
    <b-container v-if="article" :fluid="article.full_width">
      <h1>{{ article.title }}</h1>

      <h6
        v-if="article.authors && article.authors.length != 0"
        class="text-muted"
      >
        By {{ authors.join(", ") }}
      </h6>

      <b-img-lazy
        v-if="article.show_thumbnail"
        fluid
        :src="article.thumbnail_url"
        class="mb-3"
      />

      <hr />

      <span v-html="article.html" />
    </b-container>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      article: null,
      authors: [],
    };
  },
  async created() {
    let response = await this.$http.get(
      this.$store.state.apiURL + "/articles/" + this.id,
      {
        headers: {
          Authorization: "Bearer " + this.$store.state.JWT,
        },
      }
    );

    let users = await this.$http.get(this.$store.state.apiURL + "/users", {
      headers: {
        Authorization: "Bearer " + this.$store.state.JWT,
      },
      params: {
        admin: true,
      },
    });

    let user = await this.$http.get(
      this.$store.state.apiURL + "/users/" + this.$store.state.id,
      {
        headers: {
          Authorization: "Bearer " + this.$store.state.JWT,
        },
      }
    );
    let temp = users.data.data.concat([user.data]);
    let all_authors = temp.map((user) => {
      return {
        id: user.id,
        name: user.first_name + " " + user.last_name,
      };
    });

    if (response.data.authors) {
      this.authors = response.data.authors.map(
        (id) => all_authors.filter((auth) => auth.id == id)[0].name
      );
    }

    this.article = response.data;
    this.$route.meta.title = this.article.title;
  },
};
</script>

<style lang="scss">
@import "../../scss/theme.scss"; 

</style>